import React from 'react';
import { useSpring, animated } from 'react-spring';
import './index.scss';

function RocketQueen() {
  return (
    <div>
      <div className='fly-container'>
        <div className='fly-lucky' />
        <div className='fire' />
      </div>
    </div>
  );
}
export default RocketQueen;
