import React from 'react';
import { useSpring, animated } from 'react-spring';

import './index.scss';
import logoAviator from '../../../statics/assets/images/rocket/14d002de897a550d48d9c210f41f67e4.png';

function Loading() {
  const hair = {
    overflow: 'visible',
  };
  const hairBack = {
    height: '180px',
  };
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const duration = getRandomInt(4000, 6000);
  const progressProps = useSpring({
    width: '100%',
    from: { width: '0%' },
    config: { duration, delay: 500, tension: 100, friction: 500 },
  });

  return (
    <div className='loading'>
      <div className='loading__content'>
        {/* <img
          className='loading__content_logo'
          src={logoAviator}
          alt='Aviator'
        /> */}
        <div className='middle'>
          <div className='animation'>
            <div className='content'>
              <div className='item bg-shine' />
              <div className='item mask' style={hair}>
                <div className='item head'>
                  <div
                    className='item bg bg-hair-back-right'
                    style={hairBack}
                  />
                </div>
              </div>
              <div className='item bg-circle' />
              <div className='item mask'>
                <div className='item head'>
                  <div className='item bg bg-hair-back-left' />
                  <div className='item bg bg-hair-back-middle' />
                  <div className='item bg bg-hair-back-right' />
                </div>
              </div>
              <div className='item left-hand'>
                <div className='item bg bg-hand-left-3' />
              </div>
              <div className='item mask'>
                <div className='item body'>
                  <div className='item bg bg-body' />
                  <div className='item bg bg-boobs' />
                </div>
              </div>
              <div className='item head'>
                <div className='item bg bg-eyeball' />
                <div className='item bg bg-pupils' />
                <div className='item bg bg-earring-left' />
                <div className='item bg bg-face' />
                <div className='item bg bg-earring-right' />
                <div className='item bg bg-cheeks-ahegao' />
                <div className='item bg bg-bang-left' />
                <div className='item bg bg-bang-right' />
                <div className='item bg bg-bang-middle' />
                <div className='item bg bg-glasses-bg' />
                <div className='item bg bg-glasses-sh' />
                <div className='item bg bg-glasses' />
              </div>
              <div className='item left-hand'>
                <div className='item elbow-left'>
                  <div className='item palm-left'>
                    <div className='item bg bg-thumb' />
                  </div>
                  <div className='item bg bg-hand-left-2' />
                  <div className='item palm-left'>
                    <div className='item bg bg-hand-left-1' />
                  </div>
                </div>
              </div>
              <div className='item right-hand'>
                <div className='item bg bg-hand-right-3' />
                <div className='item elbow-right'>
                  <div className='item bg bg-hand-right-2' />
                  <div className='item palm-right'>
                    <div className='item bg bg-palm' />
                    <div className='item bra'>
                      <div className='item bg bg-bra-top' />
                      <div className='item bg bg-strap-1' />
                      <div className='item bra-bottom'>
                        <div className='item bg bg-bra-bottom' />
                        <div className='item bg bg-strap-2' />
                      </div>
                    </div>
                    <div className='item bg bg-little-finger' />
                  </div>
                </div>
              </div>
              <div className='item mask'>
                <div className='item bg bg-cloud' />
              </div>
              <div className='item bg bg-heart' />
            </div>
          </div>
          <div className='rocket-queen-logo' />
        </div>
        <div
          className='loading__content_progress'
          role='progressbar'
          aria-label='Basic example'
          aria-valuenow='75'
          aria-valuemin='0'
          aria-valuemax='100'
        >
          <animated.div
            className='loading__content_progress-bar'
            style={progressProps}
          />
        </div>
      </div>
    </div>
  );
}
export default Loading;
