import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import loadRocketQueen from '../../statics/assets/images/rocket/ed9ee1f807824a3fc3f2da57685ddba3.svg';
import RocketQueen from '../Design/RocketQueen';

import './index.scss';

function MainContent() {
  const [showButton, setShowButton] = useState(false);
  const [generatedNumber, setGeneratedNumber] = useState(null);
  const [canGenerateNumber, setCanGenerateNumber] = useState(true);
  const [nextGenerationTime, setNextGenerationTime] = useState(null);
  const [plusTwoMinutesTime, setPlusTwoMinutesTime] = useState(null);
  const [showStartButton, setShowStartButton] = useState(true);

  const showBetReminderToast = () => {
    toast.info(
      'Внимание! Больше точных прогнозов в реальном времени можно получить в нашем Telegram AI-Боте 👇👇👇',
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
        closeOnClick: false,
        className: 'bet-reminder-toast',
      },
    );
  };

  const generateNumber = () => {
    if (canGenerateNumber) {
      setShowButton(true);
      const randomNumber = Math.random();
      let number;
      if (randomNumber < 0.7) {
        number = (Math.random() * (2 - 1.5) + 1.5).toFixed(2);
      } else if (randomNumber < 0.9) {
        number = (Math.random() * (3 - 2) + 2).toFixed(2);
      } else {
        number = (Math.random() * (5 - 3) + 3).toFixed(2);
      }

      setGeneratedNumber(number);
      setCanGenerateNumber(false);
      setShowStartButton(false);

      const nextTime = new Date(Date.now() + 2 * 60 * 1000);
      setNextGenerationTime(formatTime(nextTime));
      const plusTwoMinutes = new Date(Date.now() + 2 * 60 * 1000);
      showBetReminderToast(true);
      setPlusTwoMinutesTime(formatTime(plusTwoMinutes));
    } else if (!canGenerateNumber) {
      showDelayedToast(nextGenerationTime);
    }
  };

  const formatTime = (time) => {
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <div>
      <div className='main-content'>
        <div className='main-container'>
          <RocketQueen />
          <div className='main-container__logo-image'>
            <div className='main-container__logo-image_gray-circle'>
              {generatedNumber ? (
                <a
                  target='_blank'
                  href='https://t.me/casinohack_bot'
                  className='result'
                  rel='noreferrer'
                >
                  👉 Получить Сигнал 👈
                </a>
              ) : (
                <img
                  src={loadRocketQueen}
                  width='200px'
                  height='100px'
                  alt='RocketQueen'
                />
              )}
            </div>
          </div>
        </div>
        <div className='bottom-container'>
          <p className='bottom-container__bottom-text'>
            Прогнозы действуют только на официальных
            <br /> сайтах и актуальных зеркалах 1WIN
          </p>
          {showStartButton && (
            <Button
              onClick={generateNumber}
              className='button'
              variant='outline-secondary'
            >
              Начать
            </Button>
          )}
          {showButton && (
            <Button
              target='_blank'
              href='https://t.me/casinohack_bot'
              className='buttonPlay'
              variant='outline-secondary'
            >
              Telegram Bot
            </Button>
          )}
          <ToastContainer theme='dark' />
        </div>
      </div>
    </div>
  );
}

export default MainContent;
